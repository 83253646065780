import api from '$lib/api'
import { getCaptchaToken } from '$lib/utils'

export interface ContactParams {
    e: CustomEvent
    component: string
    slug: string
}

export async function submitContact({
    e,
    component,
    slug,
}: ContactParams): Promise<void> {
    const { email, note, subject } = e.detail
    const captcha = await getCaptchaToken()
    const res = await api.emails.feedback({
        captcha,
        component,
        email,
        lang: navigator.language,
        note,
        slug,
        subject,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    if (!res.ok) {
        throw new Error(res.error.key)
    }
}
